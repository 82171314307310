<template>
  <section>
    <hero-global img="/img/suffer-discrimination/suffer-discrimination_1.svg"
                 first-width="100"
                 last-width="20"
                 title="¿Sufre discriminación por discapacidad?"/>
  </section>
  <section class="container p-2-rem">
    <div class="d-flex shadow p-2-rem justify-content-around align-items-center">
      <h2>Si quiere hacer una pregunta sobre sus derechos o denunciar una discriminación pincha aquí:</h2>
      <router-link to="#" class="btn btn-blue link">Más información</router-link>
    </div>
  </section>
  <section class="container p-2-rem">
    <article>
      <p>Seguro que más de una vez se ha encontrado ante situaciones en las que se ha sentido discriminado/a porque
        existían obstáculos que le impedían ejercer sus derechos. Es necesario cambiar algunas cosas de la sociedad para
        que todas las mujeres y hombres con discapacidad se sientan ciudadanos y ciudadanas en igualdad de condiciones.
        No por ser diferente tienen que discriminarle y es importante que usted contribuya al cambio social que se
        necesita para que la sociedad no excluya a las personas que son diferentes.</p>
      <br/>
      <p>El proceso de autodefensa puede ser largo, requiere dedicación y es posible que el resultado final no se
        exactamente el que esperaba; no obstante, sus esfuerzos siempre serán muy positivos y habrá cambiado muchas
        cosas por el camino; por ejemplo, habrá hablado con personas a las que habrá "educado" porque antes ni si quiera
        reparaban en las barreras y los obstáculos que nos ponen en una situación de desventaja. Ellos y ellas, es
        posible, que también se convertirán en "agentes del cambio" porque usted los habrá instruido.</p>
      <br/>
      <p>Puede acceder a la <a class="text-bold text-primary" href="#">Guía de Autodefensa</a> o <a class="text-bold text-primary" href="#">descargarse modelos genéricos</a>.</p>
    </article>
  </section>
  <section class="container p-2-rem">
    <div class="d-flex gap-2-rem justify-content-center">
      <div class="w-50 p-2-rem shadow text-primary font">
        <p class="font-family-frank-ruhl text-bold font-size-21 text-center">Descargue la aplicación de denuncias en su móvil Android</p>
        <br/>
        <img class="d-block w-12-rem margin-auto" src="/img/google-play-store.png" alt="Descargue la aplicación de denuncias en su móvil Android" title="Descargue la aplicación de denuncias en su móvil Android">
      </div>
      <div class="w-50 p-2-rem shadow text-primary font">
        <p class="font-family-frank-ruhl text-bold font-size-21 text-center">Descargue la aplicación de denuncias en dispositivos iPhone o iPad</p>
        <br/>
        <img class="d-block w-12-rem margin-auto" src="/img/disponible-app-store.png" alt="Descargue la aplicación de denuncias en su móvil Android" title="Descargue la aplicación de denuncias en su móvil Android">
      </div>
    </div>
    <img class="d-block margin-auto w-18-rem m-top-4-rem"
         alt="Imagen Gobierno de España"
         title="Imagen Gobierno de España"
         src="/img/gobierno-espana.png">
  </section>
  <section class="container p-2-rem">
    <other-links-interest class="links-interest-box container"/>
  </section>
  <br/>
  <br/>
</template>

<script>
import HeroGlobal from "@/components/Global/HeroGlobal";
import OtherLinksInterest from "@/components/Layout/OtherLinksInterest";

export default {
  name: "SufferDiscrimination",
  components: {OtherLinksInterest, HeroGlobal}
}
</script>

<style scoped>

</style>
